import React from 'react';

import Icon from '../Icon/Icon'

import styles from './Checkbox.module.css'

// IMPORT STYLES FROM PARENT

const Checkbox = ({ value, label, id, name, action, isChecked }) => {
    return (
        <div className={styles.checkboxWrapper}>
            <div className={styles.inputWrapper}>
                <input type="checkbox" className={styles.input} id={id} value={value} name={name} data-label={label} onChange={(e) => action(e)} checked={isChecked} />
                <span className={styles.box} role="presentation"><Icon symbol="tick" /></span>
            </div>
            <label className={styles.label} forhtml={id}>{label}</label>
        </div>
    )
};

export default Checkbox;