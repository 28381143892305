import { useState } from 'react';
import { getStorage, setStorage } from '../../../../helpers/general';
import algoliasearch from 'algoliasearch';
require('dotenv').config();

const AlgoliaSearch = async (searchTerm, limit = 100, facetFilters = {}) => {
  // console.log(facetFilters, 'facetFilters');
  // Remove 'gad_source' from facetFilters if it exists
  if ('gad_source' in facetFilters) {
    delete facetFilters['gad_source'];
  }
  const client = algoliasearch(
    process.env.ALGOLIA_APP_ID,
    process.env.ALGOLIA_API_KEY
  );

  const sortBy = {
    newest: false,
    A2Z: 'asc(name)',
    Z2A: 'desc(name)',
    PriceAsc: 'asc(calculated_price)',
    PriceDesc: 'desc(calculated_price)'
  };

  const replicaIndexes = {
    newest: process.env.ALGOLIA_INDEX_NAME,
    A2Z: process.env.ALGOLIA_INDEX_NAME_ASC,
    Z2A: process.env.ALGOLIA_INDEX_NAME_DESC,
    PriceAsc: process.env.ALGOLIA_INDEX_PRICE_ASC,
    PriceDesc: process.env.ALGOLIA_INDEX_PRICE_DESC
  };

  const index = client.initIndex(
    typeof facetFilters.sortBy !== 'undefined' &&
      replicaIndexes[facetFilters.sortBy]
      ? replicaIndexes[facetFilters.sortBy]
      : process.env.ALGOLIA_INDEX_NAME
  );

  const spencil_sortby = getStorage('spencil_sortby');
  if (!(spencil_sortby === null && facetFilters.sortBy === 'newest')) {
    if (
      typeof facetFilters.sortBy !== 'undefined' &&
      Object.keys(sortBy).indexOf(facetFilters.sortBy) > -1 &&
      facetFilters.sortBy !== spencil_sortby
    ) {
      setStorage('spencil_sortby', facetFilters.sortBy);
      await index.setSettings({
        ranking: sortBy[facetFilters.sortBy]
          ? [sortBy[facetFilters.sortBy]]
          : []
      });

      const _urlParams = new URLSearchParams(window.location.search);
      _urlParams.set('sortBy', facetFilters.sortBy);
      window.history.replaceState(
        {},
        ``,
        `${window.location.pathname}?${_urlParams
          .toString()
          .replace(/\%2B/g, '%20')
          .replace(/\+/g, '%20')}`
      ); // eslint-disable-line no-useless-escape
      // setTimeout(() => {
      //     window.location.reload()
      // }, 800)
    }
  }
  if (typeof facetFilters.sortBy !== 'undefined') {
    delete facetFilters['sortBy'];
  }

  // let numericFilters = [];

  // if (facetFilters.filters) {
  //   // Extracting calculated_price filters
  //   const filterPattern = /calculated_price:'(\d+) to (\d+)'/g;
  //   let match;
  //   while ((match = filterPattern?.exec(facetFilters.filters)) !== null) {
  //     numericFilters.push(`calculated_price:${match[1]} to ${match[2]}`);
  //   }
  // }

  // console.log('Formatted numericFilters:', numericFilters);

  let numericFilters = [];
  if (facetFilters.filters) {
    // Remove 'gad_source' from filters if it exists
    facetFilters.filters = facetFilters.filters
      .replace(/\(gad_source:'\d+'\)\s*(AND|OR)?\s*/g, '')
      .trim();

    // Extracting calculated_price filters
    const filterPattern = /calculated_price:'(\d+) to (\d+)'/g;
    let match;
    while ((match = filterPattern.exec(facetFilters.filters)) !== null) {
      numericFilters.push(`calculated_price:${match[1]} to ${match[2]}`);
    }

    // Remove the calculated_price filters
    facetFilters.filters = facetFilters.filters
      .replace(filterPattern, '')
      .trim();

    // Remove empty parentheses and then trim any leading AND/OR
    facetFilters.filters = facetFilters.filters.replace(/\(\s*\)/g, '').trim();
    facetFilters.filters = facetFilters.filters
      .replace(/^(AND|OR)\s*/i, '')
      .trim();
  }

  // console.log('Updated filters:', facetFilters.filters);
  // console.log('numericFilters:', numericFilters);

  return new Promise(res => {
    let currentPage = 0;
    if (typeof facetFilters.page !== 'undefined') {
      currentPage = facetFilters['page'];
      delete facetFilters['page'];
    }

    index
      .search(searchTerm, {
        hitsPerPage: limit,
        ...facetFilters,
        page: currentPage,
        numericFilters: numericFilters.length > 0 ? numericFilters : undefined
        // numericFilters: ['calculated_price: 0 to 10']
      })
      .then(response => {
        const overrideRedirect = response.renderingContent?.redirect?.url;
        if (response.nbHits > 0 && 'hits' in response) {
          const allItems = response.hits.map(product => {
            product.search_item_type = 'product';
            return product;
          });
          res({
            products: allItems.slice(currentPage * 60, (currentPage + 1) * 60),
            totalPages: 'nbPages' in response ? response.nbPages : 1,
            currentPage: 'page' in response ? response.page : 0,
            perPage: 'page' in response ? response.hitsPerPage : 60,
            overrideRedirect,
            allItems
          });
        } else {
          res({
            products: [],
            totalPages: 0,
            currentPage: 0,
            overrideRedirect
          });
        }
      });
  });
};

export default AlgoliaSearch;
