// import BigCommerceSearch from './searches/BigCommerceSearch.js'
// import WordPressSearch from './searches/WordPressSearch.js'
import AlgoliaSearch from './searches/AlgoliaSearch.js';
// import SearchSpringSearch from './searches/SearchSpringSearch.js'

const ProcessSearch = (searchTerm, limit = 100, facetFilters = {}, page) => {
  return new Promise(res => {
    const resultPromises = [];
    // resultPromises.push(BigCommerceSearch(searchTerm, limit))
    // resultPromises.push(WordPressSearch(searchTerm, limit))
    resultPromises.push(AlgoliaSearch(searchTerm, limit, facetFilters, page));

    Promise.all(resultPromises).then(results => {
      // console.log(results);
      let total = 0;
      results?.map(data => {
        const items = Object.values(data);
        total += items[0]?.length;
        return true;
      });

      const totals = {
        count: total
      };

      res({ items: results, totals });
    });
  });
};

export default ProcessSearch;
